const parseMenuTree = (menuList) => {
  let menuTree = [];
  let menuIdMap = {};
  let menuUrlMap = {};
  let actionPermissionMap = {};
  let authAllMap = {};
  menuList.forEach(t => {
    menuIdMap[t.id] = t;
    if (t.menuUrl) {
      if (menuUrlMap[t.menuUrl]) {
        menuUrlMap[t.menuUrl].push(t);
      } else {
        menuUrlMap[t.menuUrl] = [t];
      }
    }

    if (t.type === 1 || t.type === 2) {
      // 菜单资源重新设置对应的URL
      Object.defineProperty(t, 'url', {
        enumerable: true,
        configurable: true,
        get: () => {
          if (t.menuUrl) {
            return t.menuUrl;
          }
          if (t.children && t.children[0]) {
            return t.children[0].menuUrl;
          }
          return '';
        },
      });
    } else {
      // 操作按钮资源
      actionPermissionMap[t.authCode] = true;
    }
    if (t.type !== 1) {
      authAllMap[t.authCode] = true;
    }
  });
  menuList.forEach(t => {
    // 处理根节点
    if (t.type === 1 || t.type === 2) {
      if (t.level === 1) {
        if (!t.children) {
          t.children = [];
        }
        menuTree.push(t);
      } else {
        // 处理其他节点
        // 判断父节点是否有children，若没有，则初始化
        // 若有则，直接在父节点的children中插入
        if (t.pid && menuIdMap[t.pid]) {
          if (!menuIdMap[t.pid].children) {
            menuIdMap[t.pid].children = [];
          }
          let index = menuIdMap[t.pid].children.findIndex(c => c.sort >= t.sort);
          if (index < 0) {
            menuIdMap[t.pid].children.push(t);
          } else {
            menuIdMap[t.pid].children.splice(index, 0, t);
          }
        }
      }
    }
  });
  return {menuTree, menuIdMap, menuUrlMap, actionPermissionMap, authAllMap};
};

const flattenTree = (menuList, treeMap, flattenList) => {
  treeMap = treeMap || {};
  flattenList = flattenList || [];
  menuList.forEach(t => {
    if (!treeMap[t.id]) {
      treeMap[t.id] = t;
      flattenList.push(t);
      if (t.children) {
        flattenTree(t.children, treeMap, flattenList);
      }
    }
  });
  return { treeMap, flattenList };
};

export { parseMenuTree, flattenTree };
