import LoginApi from '@/api/login';
import { parseMenuTree } from '@/utils/menuTree';

const initLevel1 = (menuObj) => {
  if (!menuObj.children) {
    return menuObj.menuUrl;
  }
  return initLevel1(menuObj.children[0]);
};

export default {
  getMenuInfo ({ commit }) {
    return LoginApi.getTreeMenuListWithAuth().then(res => {
      localStorage.setItem('employeeMenuList', JSON.stringify(res.body || []));

      let {menuTree, menuIdMap, menuUrlMap, actionPermissionMap, authAllMap} = parseMenuTree(res.body);

      // 默认第一个子菜单的第一个菜单
      menuTree.map(tree => {
        if (tree.level === 1 && tree.children && tree.children.length) {
          tree.menuUrl = initLevel1(tree.children[0]);
        }
        return tree;
      });

      commit('UPDATE_MENU_TREE', menuTree);
      commit('UPDATE_MENU_ID_MAP', menuIdMap);
      commit('UPDATE_MENU_URL_MAP', menuUrlMap);
      commit('UPDATE_ACTION_PERMISSION', actionPermissionMap);
      commit('UPDATE_AUTH_ALL_MAP', authAllMap);
      return res.body;
    });
  },
};
